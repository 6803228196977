L.SVGIcon = L.Icon.extend({
    options: {
        iconAnchor: null,
        iconSize: L.point(24,32),
        iconClassName: 'svg-icon',
        iconFillColor: '#EA4335',
        iconFillOpacity: '1.0',
        iconViewBox: '0 0 384 512',
        iconPath: 'M172.3 501.7C27 291 0 269.4 0 192 0 86 86 0 192 0s192 86 192 192c0 77.4-27 99-172.3 309.7-9.5 13.8-29.9 13.8-39.5 0z',
        shadowAnchor: null,
        shadowSize: L.point(24,24),
        shadowClassName: 'svg-shadow',
        shadowFillColor: '#000',
        shadowFillOpacity: '0.2',
        shadowViewBox: '0 0 256 256',
        shadowPath: 'M80 128a48 32 0 1 0 96 0a48 32 0 1 0-96 0',
        popupAnchor: null,
        text: null,
        textAnchor: L.point(192,300),
        textFillColor: '#FFF',
        textFontSizeLg: '224px',
        textFontSizeSm: '176px',
        textFontSize: '224px'
    },
    initialize: function(options) {
        options = L.Util.setOptions(this, options);
        options.iconSize = L.point(options.iconSize);
        if (!options.iconAnchor) {
            options.iconAnchor = L.point(Number(options.iconSize.x)/2, Number(options.iconSize.y));
        } else {
            options.iconAnchor = L.point(options.iconAnchor);
        }
        if (!options.popupAnchor) {
            options.popupAnchor = L.point(0, (-0.75)*(options.iconSize.y));
        } else {
            options.popupAnchor = L.point(option.popupAnchor);
        }
        if (!options.shadowAnchor) {
            options.shadowAnchor = L.point(Number(options.shadowSize.x)/2, Number(options.shadowSize.y)/2);
        } else {
            options.shadowAnchor = L.point(options.shadowAnchor);
        }
    },
    createIcon: function() {
        var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.classList.add(this.options.iconClassName);
        if (this.options.customClasses) {
            for (var i=0; i<this.options.customClasses.length; i++) {
                svg.classList.add(this.options.customClasses[i]);
            }
        }
        svg.setAttribute('viewBox', this.options.iconViewBox);
        var fillColor = (this.options.fillColor) ? this.options.fillColor : this.options.iconFillColor;
        var path = '<path d="'+this.options.iconPath+'" fill="'+fillColor+'" />';
        var text = '';
        if (this.options.text) {
            var fontSize = this.options.textFontSize;
            if (this.options.textFontSizeLg && this.options.textFontSizeSm) {
                fontSize = (typeof this.options.text === 'number' && this.options.text > 99) ? this.options.textFontSizeSm : this.options.textFontSizeLg;
            }
            text = '<text text-anchor="middle" x="'+this.options.textAnchor.x+'" y="'+this.options.textAnchor.y+'" style="font-size:'+fontSize+';" fill="'+this.options.textFillColor+'">'+this.options.text+'</text>';
        }
        svg.innerHTML = path + text;
        svg.style.width = this.options.iconSize.x + 'px';
        svg.style.height = this.options.iconSize.y + 'px';
        var anchor = this.options.iconAnchor || this.options.iconSize && this.options.iconSize.divideBy(2, true);
        if (anchor) {
            svg.style.marginLeft = (-anchor.x) + 'px';
            svg.style.marginTop = (-anchor.y) + 'px';
        }
        return svg;
    },
    createShadow: function() {
        var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.classList.add(this.options.shadowClassName);
        svg.setAttribute('viewBox', this.options.shadowViewBox);
        var path = '<path d="'+this.options.shadowPath+'" fill="'+this.options.shadowFillColor+'" fill-opacity="'+this.options.shadowFillOpacity+'" />';
        svg.innerHTML = path;
        svg.style.width = this.options.shadowSize.x + 'px';
        svg.style.height = this.options.shadowSize.y + 'px';
        var anchor = this.options.shadowAnchor || this.options.shadowSize && this.options.shadowSize.divideBy(2, true);
        if (anchor) {
            svg.style.marginLeft = (-anchor.x) + 'px';
            svg.style.marginTop = (-anchor.y) + 'px';
        }
        return svg;
    }
});

L.Marker.SVGMarker = L.Marker.extend({
    options: {},
    initialize: function(latlng, options) {
        options = L.Util.setOptions(this, options);
        options.icon = new L.SVGIcon(options.iconOptions);
        this._latlng = latlng;
    }
});

L.marker.svgMarker = function(latlng, options) {
    return new L.Marker.SVGMarker(latlng, options);
}


// https://github.com/elmarquis/Leaflet.GestureHandling
/*
* * Leaflet Gesture Handling **
* * Version 1.1.8
*/
(function (global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' ? factory(exports) :
    typeof define === 'function' && define.amd ? define('leafletGestureHandling', ['exports'], factory) :
    (factory((global.leafletGestureHandling = {})));
}(this, (function (exports) { 'use strict';

    var LanguageContent = {
        //English
        en: {
            touch: "Use two fingers to move the map",
            scroll: "Use ctrl + scroll to zoom the map",
            scrollMac: "Use \u2318 + scroll to zoom the map"
        },
        //Spanish
        es: {
            touch: "Para mover el mapa, utiliza dos dedos",
            scroll: "Mant\u00e9n pulsada la tecla Ctrl mientras te desplazas para acercar o alejar el mapa",
            scrollMac: "Mant\u00e9n pulsada la tecla \u2318 mientras te desplazas para acercar o alejar el mapa"
        }
    };

    /*
    * * Leaflet Gesture Handling **
    * * Version 1.1.8
    */

    L.Map.mergeOptions({
        gestureHandlingOptions: {
            text: {},
            duration: 1000
        }
    });

    var draggingMap = false;

    var GestureHandling = L.Handler.extend({
        addHooks: function () {
            this._handleTouch = this._handleTouch.bind(this);

            this._setupPluginOptions();
            this._setLanguageContent();
            this._disableInteractions();

            //Uses native event listeners instead of L.DomEvent due to issues with Android touch events
            //turning into pointer events
            this._map._container.addEventListener("touchstart", this._handleTouch);
            this._map._container.addEventListener("touchmove", this._handleTouch);
            this._map._container.addEventListener("touchend", this._handleTouch);
            this._map._container.addEventListener("touchcancel", this._handleTouch);
            this._map._container.addEventListener("click", this._handleTouch);

            L.DomEvent.on(this._map._container, "mousewheel", this._handleScroll, this);
            L.DomEvent.on(this._map, "mouseover", this._handleMouseOver, this);
            L.DomEvent.on(this._map, "mouseout", this._handleMouseOut, this);

            // Listen to these events so will not disable dragging if the user moves the mouse out the boundary of the map container whilst actively dragging the map.
            L.DomEvent.on(this._map, "movestart", this._handleDragging, this);
            L.DomEvent.on(this._map, "move", this._handleDragging, this);
            L.DomEvent.on(this._map, "moveend", this._handleDragging, this);
        },

        removeHooks: function () {
            this._enableInteractions();

            this._map._container.removeEventListener("touchstart", this._handleTouch);
            this._map._container.removeEventListener("touchmove", this._handleTouch);
            this._map._container.removeEventListener("touchend", this._handleTouch);
            this._map._container.removeEventListener("touchcancel", this._handleTouch);
            this._map._container.removeEventListener("click", this._handleTouch);

            L.DomEvent.off(this._map._container, "mousewheel", this._handleScroll, this);
            L.DomEvent.off(this._map, "mouseover", this._handleMouseOver, this);
            L.DomEvent.off(this._map, "mouseout", this._handleMouseOut, this);

            L.DomEvent.off(this._map, "movestart", this._handleDragging, this);
            L.DomEvent.off(this._map, "move", this._handleDragging, this);
            L.DomEvent.off(this._map, "moveend", this._handleDragging, this);
        },

        _handleDragging: function (e) {
            if (e.type == "movestart" || e.type == "move") {
                draggingMap = true;
            } else if (e.type == "moveend") {
                draggingMap = false;
            }
        },

        _disableInteractions: function () {
            this._map.dragging.disable();
            this._map.scrollWheelZoom.disable();
            if (this._map.tap) {
                this._map.tap.disable();
            }
        },

        _enableInteractions: function () {
            this._map.dragging.enable();
            this._map.scrollWheelZoom.enable();
            if (this._map.tap) {
                this._map.tap.enable();
            }
        },

        _setupPluginOptions: function () {
            //For backwards compatibility, merge gestureHandlingText into the new options object
            if (this._map.options.gestureHandlingText) {
                this._map.options.gestureHandlingOptions.text = this._map.options.gestureHandlingText;
            }
        },

        _setLanguageContent: function () {
            var languageContent;
            //If user has supplied custom language, use that
            if (this._map.options.gestureHandlingOptions && this._map.options.gestureHandlingOptions.text && this._map.options.gestureHandlingOptions.text.touch && this._map.options.gestureHandlingOptions.text.scroll && this._map.options.gestureHandlingOptions.text.scrollMac) {
                languageContent = this._map.options.gestureHandlingOptions.text;
            } else {
                //Otherwise auto set it from the language files

                //Determine their language e.g fr or en-US
                var lang = this._getUserLanguage();

                //If we couldn't find it default to en
                if (!lang) {
                    lang = "en";
                }

                //Lookup the appropriate language content
                if (LanguageContent[lang]) {
                    languageContent = LanguageContent[lang];
                }

                //If no result, try searching by the first part only. e.g en-US just use en.
                if (!languageContent && lang.indexOf("-") !== -1) {
                    lang = lang.split("-")[0];
                    languageContent = LanguageContent[lang];
                }

                if (!languageContent) {
                    // If still nothing, default to English
                    lang = "en";
                    languageContent = LanguageContent[lang];
                }
            }

            //Check if they're on a mac for display of command instead of ctrl
            var mac = false;
            if (navigator.platform.toUpperCase().indexOf("MAC") >= 0) {
                mac = true;
            }

            var scrollContent = languageContent.scroll;
            if (mac) {
                scrollContent = languageContent.scrollMac;
            }

            this._map._container.setAttribute("data-gesture-handling-touch-content", languageContent.touch);
            this._map._container.setAttribute("data-gesture-handling-scroll-content", scrollContent);
        },

        _getUserLanguage: function () {
            var lang = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
            return lang;
        },

        _handleTouch: function (e) {
            //Disregard touch events on the minimap if present
            var ignoreList = ["leaflet-control-minimap", "leaflet-interactive", "leaflet-popup-content", "leaflet-popup-content-wrapper", "leaflet-popup-close-button", "leaflet-control-zoom-in", "leaflet-control-zoom-out"];

            var ignoreElement = false;
            for (var i = 0; i < ignoreList.length; i++) {
                if (L.DomUtil.hasClass(e.target, ignoreList[i])) {
                    ignoreElement = true;
                }
            }

            if (ignoreElement) {
                if (L.DomUtil.hasClass(e.target, "leaflet-interactive") && e.type === "touchmove" && e.touches.length === 1) {
                    L.DomUtil.addClass(this._map._container, "leaflet-gesture-handling-touch-warning");
                    this._disableInteractions();
                } else {
                    L.DomUtil.removeClass(this._map._container, "leaflet-gesture-handling-touch-warning");
                }
                return;
            }
            // screenLog(e.type+' '+e.touches.length);
            if (e.type !== "touchmove" && e.type !== "touchstart") {
                L.DomUtil.removeClass(this._map._container, "leaflet-gesture-handling-touch-warning");
                return;
            }
            if (e.touches.length === 1) {
                L.DomUtil.addClass(this._map._container, "leaflet-gesture-handling-touch-warning");
                this._disableInteractions();
            } else {
                this._enableInteractions();
                L.DomUtil.removeClass(this._map._container, "leaflet-gesture-handling-touch-warning");
            }
        },

        _isScrolling: false,

        _handleScroll: function (e) {
            if (e.metaKey || e.ctrlKey) {
                e.preventDefault();
                L.DomUtil.removeClass(this._map._container, "leaflet-gesture-handling-scroll-warning");
                this._map.scrollWheelZoom.enable();
            } else {
                L.DomUtil.addClass(this._map._container, "leaflet-gesture-handling-scroll-warning");
                this._map.scrollWheelZoom.disable();

                clearTimeout(this._isScrolling);

                // Set a timeout to run after scrolling ends
                this._isScrolling = setTimeout(function () {
                    // Run the callback
                    var warnings = document.getElementsByClassName("leaflet-gesture-handling-scroll-warning");
                    for (var i = 0; i < warnings.length; i++) {
                        L.DomUtil.removeClass(warnings[i], "leaflet-gesture-handling-scroll-warning");
                    }
                }, this._map.options.gestureHandlingOptions.duration);
            }
        },

        _handleMouseOver: function (e) {
            this._enableInteractions();
        },

        _handleMouseOut: function (e) {
            if (!draggingMap) {
                this._disableInteractions();
            }
        }

    });

    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    exports.GestureHandling = GestureHandling;
    exports.default = GestureHandling;

    Object.defineProperty(exports, '__esModule', { value: true });

})));


// https://gitlab.com/IvanSanchez/Leaflet.TileLayer.HERE
// Leaflet class TileLayer.HERE
// Tile layer for HERE maps tiles.
L.TileLayer.HERE = L.TileLayer.extend({

  options: {
    subdomains: '1234',
    minZoom: 2,
    maxZoom: 18,

    // option scheme: String = 'normal.day'
    // The "map scheme", as documented in the HERE API.
    scheme: 'normal.day',

    // option resource: String = 'maptile'
    // The "map resource", as documented in the HERE API.
    resource: 'maptile',

    // option mapId: String = 'newest'
    // Version of the map tiles to be used, or a hash of an unique map
    mapId: 'newest',

    // option format: String = 'png8'
    // Image format to be used (`png8`, `png`, or `jpg`)
    format: 'png8',

    // option appId: String = ''
    // Required option. The `app_id` provided as part of the HERE credentials
    appId: '',

    // option appCode: String = ''
    // Required option. The `app_code` provided as part of the HERE credentials
    appCode: '',

    // option useCIT: boolean = false
    // Whether to use the CIT when loading the here-maptiles
    useCIT: false,

    // option useHTTPS: boolean = true
    // Whether to use HTTPS when loading the here-maptiles
    useHTTPS: true,

    // option language: String = ''
    // The language of the descriptions on the maps that are loaded
    language: '',

    // option language: String = ''
    // The second language of the descriptions on the maps that are loaded
    language2: '',
  },


  initialize: function initialize(options) {
    options = L.setOptions(this, options);

    // Decide if this scheme uses the aerial servers or the basemap servers
    var schemeStart = options.scheme.split('.')[0];
    options.tileResolution = 256;

    // {Base URL}{Path}/{resource (tile type)}/{map id}/{scheme}/{zoom}/{column}/{row}/{size}/{format}
    // ?apiKey={YOUR_API_KEY}
    // &{param}={value}

    var params = [
      'apiKey=' + encodeURIComponent(options.apiKey)
    ];
    // Fallback to old app_id,app_code if no apiKey passed
    if(!options.apiKey) {
      params = [
        'app_id=' + encodeURIComponent(options.appId),
        'app_code=' + encodeURIComponent(options.appCode),
      ];
    }
    if(options.language) {
      params.push('lg=' + encodeURIComponent(options.language));
    }
    if(options.language2) {
      params.push('lg2=' + encodeURIComponent(options.language2));
    }
    var urlQuery = '?' + params.join('&');

    var path = '/maptile/2.1/{resource}/{mapId}/{scheme}/{z}/{x}/{y}/{tileResolution}/{format}' + urlQuery;
    var attributionPath = '/maptile/2.1/copyright/{mapId}?apiKey={apiKey}';

    var baseUrl = 'maps.ls.hereapi.com';

    // Old style with apiId/apiCode for compatibility
    if(!options.apiKey) {
      // make sure the CIT-url can be used
      baseUrl = 'maps' + (options.useCIT ? '.cit' : '') + '.api.here.com';
      attributionPath = '/maptile/2.1/copyright/{mapId}?app_id={appId}&app_code={appCode}';
    }

    var tileServer = 'base.' + baseUrl;
    if (schemeStart == 'satellite' || schemeStart == 'terrain' || schemeStart == 'hybrid') {
      tileServer = 'aerial.' + baseUrl;
    }
    if (options.scheme.indexOf('.traffic.') !== -1) {
      tileServer = 'traffic' + baseUrl;
    }

    var protocol = 'http' + (options.useHTTPS ? 's' : '');
    var tileUrl = protocol + '://{s}.' + tileServer + path;

    this._attributionUrl = L.Util.template(protocol + '://1.' + tileServer + attributionPath, this.options);

    L.TileLayer.prototype.initialize.call(this, tileUrl, options);

    this._attributionText = '';

  },

  onAdd: function onAdd(map) {
    L.TileLayer.prototype.onAdd.call(this, map);

    if (!this._attributionBBoxes) {
      this._fetchAttributionBBoxes();
    }
  },

  onRemove: function onRemove(map) {
    //
    // Remove the attribution text, and clear the cached text so it will be recalculated
    // if/when we are shown again.
    //
    this._map.attributionControl.removeAttribution(this._attributionText);
    this._attributionText = '';

    this._map.off('moveend zoomend resetview', this._findCopyrightBBox, this);

    //
    // Call the prototype last, once we've tidied up our own changes
    //
    L.TileLayer.prototype.onRemove.call(this, map);
  },

  _fetchAttributionBBoxes: function _onMapMove() {
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = L.bind(function(){
      if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        this._parseAttributionBBoxes(JSON.parse(xmlhttp.responseText));
      }
    }, this);
    xmlhttp.open("GET", this._attributionUrl, true);
    xmlhttp.send();
  },

  _parseAttributionBBoxes: function _parseAttributionBBoxes(json) {
    if (!this._map) { return; }
    var providers = json[this.options.scheme.split('.')[0]] || json.normal;
    for (var i=0; i<providers.length; i++) {
      if (providers[i].boxes) {
        for (var j=0; j<providers[i].boxes.length; j++) {
          var box = providers[i].boxes[j];
          providers[i].boxes[j] = L.latLngBounds( [ [box[0], box[1]], [box[2], box[3]] ]);
        }
      }
    }

    this._map.on('moveend zoomend resetview', this._findCopyrightBBox, this);

    this._attributionProviders = providers;

    this._findCopyrightBBox();
  },

  _findCopyrightBBox: function _findCopyrightBBox() {
    if (!this._map) { return; }
    var providers = this._attributionProviders;
    var visibleProviders = [];
    var zoom = this._map.getZoom();
    var visibleBounds = this._map.getBounds();

    for (var i=0; i<providers.length; i++) {
      if (providers[i].minLevel <= zoom && providers[i].maxLevel >= zoom) {

        if (!providers[i].boxes) {
          // No boxes = attribution always visible
          visibleProviders.push(providers[i]);
        } else {
          for (var j=0; j<providers[i].boxes.length; j++) {
            var box = providers[i].boxes[j];
            if (visibleBounds.intersects(box)) {
              visibleProviders.push(providers[i]);
              break;
            }
          }
        }
      }
    }

    var attributions = ['<a href="https://legal.here.com/en-gb/terms" target="_blank" rel="noopener noreferrer">HERE maps</a>'];
    for (var i=0; i<visibleProviders.length; i++) {
      var provider = visibleProviders[i];
      attributions.push('<abbr title="' + provider.alt + '">' + provider.label + '</abbr>');
    }

    var attributionText = '© ' + attributions.join(', ') + '. ';

    if (attributionText !== this._attributionText) {
      this._map.attributionControl.removeAttribution(this._attributionText);
      this._map.attributionControl.addAttribution(this._attributionText = attributionText);
    }
  },

});

L.tileLayer.here = function(opts){
  return new L.TileLayer.HERE(opts);
}